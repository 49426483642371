const getSettings = () => ({
  pins: [
    { supportedModes: [], value: 0, report: 1, analogChannel: 127 },
    { supportedModes: [], value: 0, report: 1, analogChannel: 127 },
    { supportedModes: [0, 1, 4, 11], value: 0, report: 1, analogChannel: 127 },
    {
      supportedModes: [0, 1, 3, 4, 11],
      value: 0,
      report: 1,
      analogChannel: 127,
    },
    { supportedModes: [0, 1, 4, 11], value: 0, report: 1, analogChannel: 127 },
    {
      supportedModes: [0, 1, 3, 4, 11],
      value: 0,
      report: 1,
      analogChannel: 127,
    },
    {
      supportedModes: [0, 1, 3, 4, 11],
      value: 0,
      report: 1,
      analogChannel: 127,
    },
    { supportedModes: [0, 1, 4, 11], value: 0, report: 1, analogChannel: 127 },
    { supportedModes: [0, 1, 4, 11], value: 0, report: 1, analogChannel: 127 },
    {
      supportedModes: [0, 1, 3, 4, 11],
      value: 0,
      report: 1,
      analogChannel: 127,
    },
    {
      supportedModes: [0, 1, 3, 4, 11],
      value: 0,
      report: 1,
      analogChannel: 127,
    },
    {
      supportedModes: [0, 1, 3, 4, 11],
      value: 0,
      report: 1,
      analogChannel: 127,
    },
    { supportedModes: [0, 1, 4, 11], value: 0, report: 1, analogChannel: 127 },
    { supportedModes: [0, 1, 4, 11], value: 0, report: 1, analogChannel: 127 },
    { supportedModes: [0, 1, 2, 4, 11], value: 0, report: 1, analogChannel: 0 },
    { supportedModes: [0, 1, 2, 4, 11], value: 0, report: 1, analogChannel: 1 },
    { supportedModes: [0, 1, 2, 4, 11], value: 0, report: 1, analogChannel: 2 },
    { supportedModes: [0, 1, 2, 4, 11], value: 0, report: 1, analogChannel: 3 },
    {
      supportedModes: [0, 1, 2, 4, 6, 11],
      value: 0,
      report: 1,
      analogChannel: 4,
    },
    {
      supportedModes: [0, 1, 2, 4, 6, 11],
      value: 0,
      report: 1,
      analogChannel: 5,
    },
  ],
  analogPins: [14, 15, 16, 17, 18, 19],
})

export default getSettings
